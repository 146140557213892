interface LoginParams {
  j_password: string;
  j_password_key: string;
  j_remember_me: boolean;
  j_username: string;
  j_username_key: string;
}

const login = async (data: LoginParams, signal: AbortSignal) => {
  const response = await fetch('/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  });
  const json = await response.json();
  return json as LoginResponseType;
};

export default login;
